export default [
  // {
  //   text: "Home",
  //   path: "/Home",
  //   icon: "home"
  // },
  // {
  //   text: "Regional",
  //   icon: "user",
  //   path: "/Regional"
  // },
  // {
  //   text: "Expedition",
  //   icon: "user",
  //   path: "/Expedition"
  // },
  // {
  //   text: "Price",
  //   icon: "user",
  //   path: "/Price"
  // },
  // {
  //   text: "Charge",
  //   icon: "user",
  //   path: "/Charge"
  // },
  // {
  //   text: "Calculate",
  //   icon: "user",
  //   path: "/Calculate"
  // },
  // {
  //   text: "Kanban",
  //   icon: "user",
  //   path: "/Kanban"
  // },
  {
    text: "Events",
    icon: "user",
    path: "/events"
  }
];
