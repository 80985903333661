import auth from "./auth";
import { createRouter, createWebHistory } from "vue-router";

import Home from "./views/home-page";
import Profile from "./views/profile-page";
import Tasks from "./views/tasks-page";
import defaultLayout from "./layouts/side-nav-inner-toolbar";
import simpleLayout from "./layouts/single-card";

function loadView(view) {
  return () => import (/* webpackChunkName: "login" */ `./views/${view}.vue`)
}

const router = new createRouter({
  routes: [
    {
      path: "/home",
      name: "home",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      redirect: "/events",
      component: Home
    },
    {
      path: "/profile",
      name: "profile",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Profile
    },
    {
      path: "/regional",
      name: "Regional",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import('@/views/region-page.vue')
    },
    {
      path: "/expedition",
      name: "Expedition",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import('@/views/expedition-page.vue')
    },
    {
      path: "/price",
      name: "Price",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import('@/views/price-page.vue')
    },
    {
      path: "/charge",
      name: "Charge",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import('@/views/charge-page.vue')
    },
    {
      path: "/calculate",
      name: "Calculate",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import('@/views/calculate-page.vue')
    },
    {
      path: "/kanban",
      name: "Kanban",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import('@/views/kanban-page.vue')
    },
    {
      path: "/events",
      name: "Events",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: () => import('@/views/events-page.vue')
    },
    {
      path: "/tasks",
      name: "tasks",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Tasks
    },
    {
      path: "/login-form",
      name: "login-form",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Sign In"
      },
      component: loadView("login-form")
    },
    {
      path: "/reset-password",
      name: "reset-password",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Reset Password",
        description: "Please enter the email address that you used to register, and we will send you a link to reset your password via Email."
      },
      component: loadView("reset-password-form")
    },
    {
      path: "/create-account",
      name: "create-account",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Sign Up"
      },
      component: loadView("create-account-form"),
    },
    {
      path: "/change-password/:recoveryCode",
      name: "change-password",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Change Password"
      },
      component: loadView("change-password-form")
    },
    {
      path: "/",
      redirect: "/events"
    },
    {
      path: "/recovery",
      redirect: "/home"
    },
    {
      path: "/:pathMatch(.*)*",
      redirect: "/events"
    }
  ],
  history: createWebHistory()
});

router.beforeEach((to, from, next) => {

  if (to.name === "login-form" && auth.loggedIn()) {
    next({ name: "Events" });
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!auth.loggedIn()) {
      next({
        name: "login-form",
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
