import appInfo from "./app-info";
const defaultUser = {
  username: '',
  avatarUrl: 'https://static.vecteezy.com/system/resources/previews/005/129/844/original/profile-user-icon-isolated-on-white-background-eps10-free-vector.jpg'
};

export default {
  _user: defaultUser,
  loggedIn() {
    const token = localStorage.getItem('FFC-TOKEN');
    if(token){
      this._user.username = localStorage.getItem('FFC-USER');
      return !!this._user;
    }
    return null;
  },

  async logIn(Username, Password) {
    try {
      // Send request
      const url = `${appInfo.WebAPIHost}/API/Login`;
      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify({
          Username,
          Password
        }),
        headers: {
          'content-type': 'application/json'
        }
      })
      const body = await response.json();
      localStorage.setItem('FFC-TOKEN', body.Token)
      localStorage.setItem('FFC-USER', Username);
      if(response.ok){
        this._user = { ...defaultUser, Username };
        return {
          isOk: true,
          data: this._user
        };
      }
      throw new Error('Username or password does not exist.');
    }
    catch (e){
      return {
        isOk: false,
        message: e.message
      };
    }
  },

  async logOut() {
    this._user = null;
    localStorage.removeItem('FFC-TOKEN');
  },

  async getUser() {
    try {
      return {
        isOk: true,
        data: this._user
      };
    }
    catch {
      return {
        isOk: false
      };
    }
  },

  async resetPassword(email) {
    try {
      // Send request
      console.log(email);

      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to reset password"
      };
    }
  },

  async changePassword(email, recoveryCode) {
    try {
      // Send request
      console.log(email, recoveryCode);

      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to change password"
      }
    }
  },

  async createAccount(email, password) {
    try {
      // Send request
      console.log(email, password);

      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to create account"
      };
    }
  }
};
